import React, { useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import MiniTomatoImage from '../assets/minitomato.jpg';

class Node {
  x: number;
  y: number;
  radius: number;
  dx: number;
  dy: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.radius = Math.random() * 0 + 1;
    this.dx = (Math.random() - 0.5) * 2;
    this.dy = (Math.random() - 0.5) * 2;
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.beginPath();
    ctx.arc(this.x, this.y, this.radius, 0, 0, false);
    ctx.fillStyle = 'white';
    ctx.fill();
  }

  update(canvasWidth: number, canvasHeight: number) {
    this.x += this.dx;
    this.y += this.dy;

    if (this.x > canvasWidth || this.x < 0) {
      this.dx = -this.dx;
    }

    if (this.y > canvasHeight || this.y < 0) {
      this.dy = -this.dy;
    }
  }
}

const HomeSection: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef(new Image());

  useEffect(() => {
    const canvas = canvasRef.current;
    const image = imageRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const setCanvasAndBackground = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    };

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const nodes: Node[] = [];

    const init = () => {
      nodes.length = 0;
      for (let i = 0; i < canvas.width / 10; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height;
        nodes.push(new Node(x, y));
      }
    };

    const animate = () => {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

      nodes.forEach((node) => {
        node.update(canvas.width, canvas.height);
        node.draw(ctx);
      });

      for (let i = 0; i < nodes.length; i++) {
        for (let j = i; j < nodes.length; j++) {
          const dx = nodes[i].x - nodes[j].x;
          const dy = nodes[i].y - nodes[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 100) {
            ctx.beginPath();
            ctx.moveTo(nodes[i].x, nodes[i].y);
            ctx.lineTo(nodes[j].x, nodes[j].y);
            ctx.strokeStyle = 'white';
            ctx.lineWidth = 0.1;
            ctx.stroke();
          }
        }
      }
    };

    image.src = MiniTomatoImage;
    image.onload = () => {
      setCanvasAndBackground();
      init();
      animate();
    };

    const handleResize = () => {
      setCanvasAndBackground();
      init();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      mt={4}
      mb={6}
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <canvas ref={canvasRef} style={{ display: 'block', zIndex: 1 }} />
      <Typography
        variant="h4"
        align="center"
        sx={{
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          zIndex: 2,
        }}
      >
        ag2
      </Typography>
    </Box>
  );
};

export default HomeSection;
