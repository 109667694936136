import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import MapImage from '../assets/map.jpg';
import { GoldenRatioImage, accessMapStyle } from '../styles/commonStyles';

const AboutUsSection: React.FC = () => {
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        事業情報
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>名称</TableCell>
            <TableCell>
              <List>
                <ListItem>ag2</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>所在地</TableCell>
            <TableCell>
              <List>
                <ListItem>〒002-0865 北海道札幌市北区屯田町370番地</ListItem>
              </List>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>事業内容</TableCell>
            <TableCell>
              <List>
                <ListItem>農業</ListItem>
              </List>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={MapImage} alt="Map" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={accessMapStyle}>
            <Box
              component="iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3510.0520021002217!2d141.31028803825123!3d43.13540584419337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f0b25dd231b1767%3A0xcd0c97820cf455df!2z44CSMDAyLTA4NjUg5YyX5rW36YGT5pyt5bmM5biC5YyX5Yy65bGv55Sw55S6!5e1!3m2!1sja!2sjp!4v1697331746422!5m2!1sja!2sjp"
              width="100%"
              height="100%"
              style={{ border: 0, position: 'absolute', top: 0, left: 0 }}
              allowFullScreen
              title="ag2 Access Map"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUsSection;
