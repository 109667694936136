import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme({
  palette: {
    primary: {
      main: '#2e8b57',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#2e8b57',
    },
    text: {
      primary: '#000000',
      secondary: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
  },
});

const theme = createTheme(baseTheme, {
  typography: {
    fontFamily: '"Roboto Slab", "Arial", sans-serif',
    h3: {
      fontWeight: 700,
      fontSize: '2rem',
      backgroundColor: '#2e8b57',
      color: 'white',
      padding: '0.5rem 1rem',
      borderBottom: '3px solid white',
      marginBottom: '1rem',
      marginTop: '1rem',
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: baseTheme.palette.text.primary,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 300,
      lineHeight: 1.43,
      color: baseTheme.palette.text.secondary,
    },
  },
});

export default theme;
