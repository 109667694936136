import React, { useEffect } from 'react';
import theme from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContactFormPage from './pages/ContactFormPage';
import ConfirmPage from './pages/ConfirmPage';
import ReactGA from 'react-ga4';

const App: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-ST77VDCR5B');
    ReactGA.send({
      hitType: 'pageview',
      page_path: location.pathname + location.search,
    });
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact-form" element={<ContactFormPage />} />
        <Route path="/confirm" element={<ConfirmPage />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
