import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Paper } from '@mui/material';
import MiniTomatoImage from '../assets/minitomato.jpg';
import MiniTomatoBoxImage from '../assets/minitomato_box.jpg';
import { GoldenRatioImage } from '../styles/commonStyles';

const ServiceSection: React.FC = () => {
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        生産物の紹介
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            ミニトマトほれまる
          </Typography>
          <Typography paragraph>
            みんな大好き定番ミニトマトのほれまるです！
          </Typography>
          <Typography paragraph>
            自然の恵みをそのままに、新鮮で美味しいミニトマトをお届けします！
          </Typography>
          <Typography paragraph>
            <Link to="https://jp.mercari.com/shops/product/7VUgWnKMqpv3CiVoG3Feue">
              オンラインショップ
            </Link>
            からお買い求めいただけます。
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={MiniTomatoImage} alt="Mini Tomato" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={MiniTomatoBoxImage} alt="Mini Tomato" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            ag2ミニトマト
          </Typography>
          <Typography paragraph>ag2のプレミアムミニトマトです！</Typography>
          <Typography paragraph>
            ご購入希望の方は<Link to="/contact-form">お問い合わせ</Link>
            ください。
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServiceSection;
