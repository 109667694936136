import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, Paper } from '@mui/material';
import SupplyImage from '../assets/supply.jpg';
import StationImage from '../assets/station.jpg';
import QualityImage from '../assets/quality.jpg';
import AgricultureImage from '../assets/agriculture.jpg';
import { GoldenRatioImage } from '../styles/commonStyles';

const ProductionSection: React.FC = () => {
  return (
    <Box mt={4} mb={6}>
      <Typography variant="h3" align="center" gutterBottom>
        生産者について
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            北海道札幌市の農家
          </Typography>
          <Typography paragraph>
            私たちは、北海道札幌市北区屯田町で主にミニトマトを施設栽培をしている農家です。
          </Typography>
          <Typography paragraph>
            札幌駅から車で約25分の立地にあり、テクノロジーを応用した都市型農業を実践しています。
          </Typography>
          <Typography paragraph>
            AIやIoTなどのテクノロジーを駆使し、「美味しいものを追求し続ける」、「いついかなる時でも安定供給する」、「農業の未来を明るいものにする」を信念に、農業という側面から社会に貢献します。
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={StationImage} alt="Station" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={QualityImage} alt="Quality" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            品質へのこだわり
          </Typography>
          <Typography paragraph>
            私たちは、「おいしさ」を追求することに妥協しません。
          </Typography>
          <Typography paragraph>
            生産物の糖度を始めとしたデータを日々取得しており、独自の技術により「おいしさ」を指標化しています。
          </Typography>
          <Typography paragraph>
            また、生産物だけでなく、生産のための施設や土壌を、AI/IoT技術を用いて常に制御・モニタリングしています。
          </Typography>
          <Typography paragraph>
            環境が美味しさに及ぼす影響について、科学技術による試行錯誤を繰り返しており、品質向上に対して妥協しません。
          </Typography>
          <Typography paragraph>
            日々進化するプレミアムブランド「ag2（アグアグ）」をお楽しみください。
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            安定供給へのこだわりと通年栽培
          </Typography>
          <Typography paragraph>
            欲しい時に欲しいものが手に入らない。
          </Typography>
          <Typography paragraph>
            農産物は、収穫適期があります。ミニトマトの場合は、通常は限られた温暖な期間しか栽培・収穫することが出来ません。
          </Typography>
          <Typography paragraph>
            私たちは、独自の施設栽培技術を開発し、一年を通して栽培を可能としました。
          </Typography>
          <Typography paragraph>寒い冬にもag2をお楽しみ頂けます。</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={SupplyImage} alt="Supply" />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={0}>
            <GoldenRatioImage src={AgricultureImage} alt="Agriculture" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            デジタルツインで農業をオープンに
          </Typography>
          <Typography paragraph>
            私たちは、農業を透明性のあるオープンなものにしたいと考えています。
          </Typography>
          <Typography paragraph>
            ag2では、
            <Link
              to="https://www.roblox.com/games/12852980662/unnamed"
              target="_blank"
              rel="noopener noreferrer"
            >
              Roblox
            </Link>
            を通じ、農場のデータをメタバース空間を通じて日々広く公開し、消費者の方にも生産状況を把握頂ける仕組みを開発しております。
          </Typography>
          <Typography paragraph>
            生産者だけでなく、消費者の方々も共に生産に関わることが出来る世界の実現を目指しています。
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductionSection;
